import { gql, useQuery } from "@apollo/client";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { useGetContactInfo } from "../initial/useGetContactInfo";
import {SecurityDetailsByCodeQuery, SecurityDetailsQuery} from "./types";

const SECURITY_DETAILS_QUERY = gql`
  query GetSecurityDetails(
    $securityId: Long
    $currency: String
    $filterTags: [String]
  ) {
    security(id: $securityId) {
      id
      name
      namesAsMap
      securityCode
      blockSize
      isinCode
      classType1 {
        value
        __typename
      }
      url
      url2
      currency {
        securityCode
        amountDecimalCount
      }
      latestMarketData {
        id
        date: obsDate
        price: closeView
      }
      type {
        id
        code
        namesAsMap
        name
      }
      fxRate(quoteCurrency: $currency)
      tagsAsSet
      documents(filterTags: $filterTags) {
        fileName
        identifier
        mimeType
      }
      amountDecimalCount
    }
  }
`;

const SECURITY_DETAILS_BY_CODE_QUERY = gql`
query GetSecurityDetails(
    $tradableTag: [String]
    $currency: String
    $securityCode: String
  ) {
    securities(
      tags: $tradableTag
      securityCode: $securityCode
    ) {
      id
      name
      namesAsMap
      isinCode
      classType1 {
        value
        __typename
      }
      securityCode
      blockSize
      url
      url2
      updateCode3
      country {
        id
        name
        code
        namesAsMap
      }
      latestMarketData {
        id
        date: obsDate
        price: closeView
      }
      type {
        id
        name
        code
        namesAsMap
      }
      currency {
        id
        securityCode
      }
      managementFee
      managementFeePercentage
      minTradeAmount
      fxRate(quoteCurrency: $currency)
      amountDecimalCount
      tagsAsSet
      profile {
        attribute(attributeKey: "security.T24.SUBASSETTYPE") {
          attributeKey
          stringValue
        }
      }
    }
  }
`;

export const useGetSecurityDetails = (
  securityCodeOrId: string | number | undefined,
  currencyCode?: string,
  byCode = true
) => {
  const { selectedContactId } = useGetContractIdData();
  const { data: { portfoliosCurrency } = { portfoliosCurrency: "EUR" } } =
    useGetContactInfo(false, selectedContactId);

  const {loading, error, data} = useQuery<SecurityDetailsQuery | SecurityDetailsByCodeQuery>(
      byCode ? SECURITY_DETAILS_BY_CODE_QUERY : SECURITY_DETAILS_QUERY,
      {
      variables: byCode ? {
        securityCode: securityCodeOrId,
        currency: currencyCode || portfoliosCurrency,
        tradableTag: ["Tradeable"],
      } : {
        securityId: securityCodeOrId,
        currency: currencyCode || portfoliosCurrency,
        filterTags: ["Online"],
      },
    }
  );

  return {
    loading,
    error,
    data: byCode
        ? (data && 'securities' in data ? data.securities[0] : null)
        : (data && 'security' in data ? data.security : null),
  };
};
