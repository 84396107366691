import { ReactComponent as InfoIcon } from "assets/information-circle.svg";
import classNames from "classnames";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { Tooltip } from "react-tooltip";

interface KIDTooltipProps {
    className?: string;
}

export const KIDTooltip = ({
    className,
}: KIDTooltipProps) => {
    const { t } = useModifiedTranslation();

    return (
        <div className={classNames('', className)}>
            <InfoIcon data-tooltip-id="tooltip-kid" data-tooltip-html={t("tradingList.kidTooltip")} />
            <Tooltip id="tooltip-kid" variant="dark"/>
        </div>
    );
};
