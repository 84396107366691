import { useModifiedTranslation } from "hooks/useModifiedTranslation";

export const useExternalUrls = () => {
    const { i18n } = useModifiedTranslation();

    const languageMapping: { [key: string]: "en_lv" | "lv_lv" | "lt_lt" | "ru_lv" } = {
        "lv-LV": "lv_lv",
        "en-US": "en_lv",
        "lt-LT": "lt_lt",
        "ru-RU": "ru_lv"
    };

    const envMapping: { [key: string]: "dev" | "test" | "prod" } = {
        "localhost": "dev",
        "investments-dev.citadele.lv": "dev",
        "investments-test.citadele.lv": "test",
        "investments.citadele.lv": "prod"
    };

    const env: "dev" | "test" | "prod" = envMapping[window.location.hostname];
    const languageCode: "en_lv" | "lv_lv" | "lt_lt" | "ru_lv" = languageMapping[i18n.language];

    const urls = {
        dev: `https://selga.citadele.lv/ibbf/${languageCode}?r=`,
        test: `https://eurotest.citadele.lv/ibbf/${languageCode}?r=`,
        prod: `https://online.citadele.lv/ibbf/${languageCode}?r=`
    };

    const depositUrl = `${urls[env]}362`;
    const withdrawUrl = `${urls[env]}363`;
    const exchangeUrl = `${urls[env]}368`;
    const fillTest = `${urls[env]}369`;

    return {
        depositUrl,
        withdrawUrl,
        exchangeUrl,
        fillTest
    };
};