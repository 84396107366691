import { ProfileClassificationCode, SecurityClassType1, SecurityTypeCode } from "../../api/holdings/types";
import { useGetFinancialProfile } from "../../api/trading/useGetFinancialProfile";

export const useProfileTestResults = (
    shortName: string | undefined, 
    securityType: string | undefined,
    securityClassType1Value: string | undefined,
): boolean => {
    // if shortName is undefined, return false
    const extID = shortName ? shortName.split('-')[0] : '';
    const { data: financialProfile, loading: loadingFinancialProfile } = useGetFinancialProfile(extID);

    if (loadingFinancialProfile || !financialProfile || !financialProfile.contacts || financialProfile.contacts.length === 0) {
        return false;
    }

    //temporary hidden - in MVP we will use Checkbox only for ETFs, later we will return checkbox for Debt instruments too
    if (securityType === SecurityTypeCode.DEBT_INSTRUMENT) {
        return false;
    }

    //If Customer classification is PROF (Professional) - checkbox never will be displayed 
    if(financialProfile.contacts[0]?.classification?.code === ProfileClassificationCode.PROF) {
        return false;
    }

    //show only for "Customer is RET (Retail)"
    if(financialProfile.contacts[0]?.classification?.code !== ProfileClassificationCode.RET) {
        return false;
    }

    //If Security is Non-Complex - do not check test result, checkbox never will be displayed
    if(securityClassType1Value === SecurityClassType1.NON_COMPLEX) {
        return false;
    }

    //show only Security is Complex
    if(securityClassType1Value !== SecurityClassType1.COMPLEX) {
        return false;
    }

    const profile = financialProfile.contacts[0].profile;

    const riskUnderstTestResult = profile.riskUnderstTestResult?.stringValue;
    const etfTestResult = profile.etfTestResult?.stringValue;
    const derivativesTestResult = profile.derivativesTestResult?.stringValue;

    if (securityType === SecurityTypeCode.DEBT_INSTRUMENT) {
        return !!derivativesTestResult && !!riskUnderstTestResult
            && (derivativesTestResult === "NOT OK" || riskUnderstTestResult === "NOT OK")
            && !!profile.derivativesExpiryDate?.dateValue && !!profile.riskUnderstExpiryDate?.dateValue
            && new Date(profile?.derivativesExpiryDate?.dateValue) >= new Date()
            && new Date(profile?.riskUnderstExpiryDate?.dateValue) >= new Date();
    } else if (securityType === SecurityTypeCode.ETF) {
        return profile && !!profile.etfExpiryDate?.dateValue 
            && new Date(profile?.etfExpiryDate?.dateValue) >= new Date() 
            && !!etfTestResult && etfTestResult === "NOT OK";
    }

    return false;
};
