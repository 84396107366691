import { gql, useQuery } from "@apollo/client";

const BUSINESS_DAY_BY_DATE_QUERY = gql`
  query T2($date: String) {
    holidayCalendar(calendarCode: "LV") {
      nextBusinessDay(date: $date)
    }
  }
`;

const NEXT_BUSINESS_DAY_QUERY = gql`
  query T1 {
    holidayCalendar(calendarCode: "LV") {
      nextBusinessDay
    }
  }
`;

export interface HolidayCalendarQuery {
  holidayCalendar?: {
    nextBusinessDay: string;
  }
}

export const useGetNextBusinessDay = (date: string|undefined = undefined) => {
  const { data, loading, error } = useQuery<HolidayCalendarQuery>(
    date ? BUSINESS_DAY_BY_DATE_QUERY : NEXT_BUSINESS_DAY_QUERY,
    {
      fetchPolicy: "network-only",
        variables: date ? {
          date: date,
        } : {}
    }
  );

  return {
    loading,
    error,
    data: data?.holidayCalendar?.nextBusinessDay,
  };
};



